import React, { useState } from 'react';

import './subscription-form.css'

const SubscriptionForm = () => {
  const [status, setStatus] = useState(null);
  const [email, setEmail] = useState('');

  const FORM_URL = `https://app.convertkit.com/forms/1917365/subscriptions`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    try {
      const response = await fetch(
        FORM_URL,
        {
          method: 'post',
          body: data,
          headers: {
            accept: 'application/json',
          },
        }
      );
      setEmail('');
      const json = await response.json();
      if (json.status === 'success') {
        setStatus('SUCCESS');
        return;
      }
    } catch (err) {
      setStatus('ERROR');
      console.log(err);
    }
  };

  const handleInputChange = event => {
    const {value} = event.target;
    setEmail(value);
  }

  return (
    <div className="sub">
      <div className="sub__header">
        <h2 >Join My Newsletter</h2>
      </div>
      <div className="sub__subheader">
        <p>
          If you've found any of my articles useful, subscribe to receive more quality articles straight to your inbox.
        </p>
      </div>

      {status === 'SUCCESS' && <p>Please go confirm your subscription!</p>}
      {status === 'ERROR' && <p>Oops, Something went wrong! try again.</p>}

      <form className="sub__form"
            action={FORM_URL}
            method="post"
            onSubmit={handleSubmit}
      >
        <div className="sub__form_field">
          <input
            className="sub__form_input"
            type="email"
            aria-label="Your email"
            //The name attribute should be the same as on you selected form.
            name="email_address"
            placeholder="Email address"
            onChange={handleInputChange}
            value={email}
            required
          />
        </div>


        <button type="submit" className='sub__form_submit'>
          Subscribe
        </button>

      </form>

      <p className="sub__tag">I won't send you spam and you can unsubscribe at any time</p>

    </div>
  );
};

export default SubscriptionForm